import * as React from 'react'
import parse from 'html-react-parser'

const Col1Section = ({ data }) => {
    return (
        <div className="oneCol container mx-auto w-3/4 lg:w-2/3 align-center py-20">
            <div>
                <h2 className="text-[2rem] leading-relaxed font-serif text-center pb-[0.5rem]">{data.sectionFields.title}</h2>
                <p className="text-[1.5rem] leading-normal font-serif text-center pb-[2rem]">{data.sectionFields.subtitle}</p>
                <div>{parse(data.sectionFields.content)}</div>
            </div>
        </div>
    )
}

export default Col1Section
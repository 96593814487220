import * as React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'


const Col2Section = ({ data }) => {
    const image = getImage(data.sectionFields.image.gatsbyImage)
    return (
        <div className="twoCol container mx-auto w-90 lg:w-2/3 align-center py-20 grid grid-cols-1 lg:grid-cols-2 lg:gap-4 content-around items-center">
            <div className="px-[2rem] mb-[1.5rem] lg:mb-0">
                <GatsbyImage image={image} layout="fullWidth" />
            </div>
            <div className="px-[2rem]">
                <h2 className="text-[2rem] leading-relaxed">{data.sectionFields.title}</h2>
                <p className="text-[1.5rem] leading-normal">{data.sectionFields.subtitle}</p>
                <div>{parse(data.sectionFields.content)}</div>
            </div>
        </div>
    )
}

export default Col2Section
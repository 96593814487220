import * as React from 'react'
import OneColSection from './columns/oneColSection'
import TwoColSection from './columns/twoColSection'

const ColLayout = ({ data }) => {
    const columnCount = data.columnCount
    return (
        <section className={`${data.background === 'gray' ? "bg-[#D9D9D9]" : "bg-[#FFFFFF]"}`}>
            {
                columnCount === 2 ? (
                    <TwoColSection data={data} />
                ) : (
                    <OneColSection data={data} />
                )
            }
        </section>
    )
}

export default ColLayout
import * as React from "react"
import { graphql } from 'gatsby'
import ColLayout from '../components/layout-components/colLayout'
import Layout from '../components/layout'

const IndexPage = ({ data }) => {
  return (
    <Layout data={data.wpPage}>
      <main>
        {
          data.wpPage.defaults.sectionPack.map(node => (
            <ColLayout data={node} />
          ))
        }
      </main>
    </Layout>
  )
}

export const query = graphql`
query {
  wpPage(slug: {eq: "sample-page"}) {
    title
    content
    slug
    nodeType
    isFrontPage
    featuredImage {
      node {
        gatsbyImage(width: 2000)
        localFile {
          childImageSharp{
            gatsbyImageData(width:200)
          }
        }
      }
    }
    defaults {
      subtitle
      sectionPack {
        fieldGroupName
        sectionName
        columnCount
        background
        sectionFields {
          content
          title
          subtitle
          fieldGroupName
          image {
            gatsbyImage(width: 1000)
          }
        }
      }
    }
    seo {
      metaDesc
      opengraphUrl
      opengraphType
      opengraphTitle
      opengraphSiteName
      title
    }
  }
}
`

export default IndexPage
